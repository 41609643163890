export function sortItemsRecursively(items) {
    if(!items || !items.length) {
        return;
    }

    items.sort((a, b) => {
        const sa = +a.label.slice(0, a.label.indexOf('-'));
        const sb = +b.label.slice(0, b.label.indexOf('-'));
        if (sa < sb) return -1;
        if (sa > sb) return 1;
        return 0;
    });

    items.forEach(item => sortItemsRecursively(item.items));
}
