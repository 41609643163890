const config = {
  gatsby: {
    pathPrefix: '/',
    siteUrl: 'https://books.wardell.biz',
    gaTrackingId: "G-MY4W52SCSG",
    trailingSlash: false,
  },
  header: {
    logo: 'https://assets.wardell.biz/books.wardell.biz/Leadership/wardell_white_logo.png',
    logoLink: 'https://books.wardell.biz',
    title:'',
    githubUrl: '',
    helpUrl: '',
    tweetText: '',
    // social: `<li>
		//     <a href="https://twitter.com/hasurahq" target="_blank" rel="noopener">
		//       <div class="twitterBtn">
		//         <img src='' alt={'Twitter'}/>
		//       </div>
		//     </a>
		//   </li>
		// 	<li>
		//     <a href="" target="_blank" rel="noopener">
		//       <div class="discordBtn">
		//         <img src='' alt={'Discord'}/>
		//       </div>
		//     </a>
		//   </li>`,
    links: [{ text: '', link: '' }],
    search: {
      enabled: true,
      indexName: 'wardell_books',
      // algoliaAppId: process.env.GATSBY_ALGOLIA_APP_ID, 
      // algoliaSearchKey: process.env.GATSBY_ALGOLIA_SEARCH_KEY,
      // algoliaAdminKey: process.env.ALGOLIA_ADMIN_KEY,
    },
  },
  sidebar: {
    forcedNavOrder: [

    ],
    collapsedNav: [
      '/1-Leadership',
      '/2-Management_1',
      '/3-Management_2',
      '/4-Marketing',
      '/5-Finance',
      '/6-Operations',
      '/7-Sales'
    ],
    links: [{ text: 'Wardell', link: 'https://wardell.biz' }],
    frontline: true,
    ignoreIndex: false,
    title:
      "wardell books",
  },
  siteMetadata: {
    title: 'Wardell International | Wardell.biz',
    description: 'Leadership, Management, Marketing, Finance, Operations, Sales',
    ogImage: null,
    docsLocation: '',
    favicon: '',
  },
  pwa: {
    enabled: false, // disabling this will also remove the existing service worker.
    manifest: {
      name: 'Gatsby Gitbook Starter',
      short_name: 'GitbookStarter',
      start_url: '/',
      background_color: '#6b37bf',
      theme_color: '#6b37bf',
      display: 'standalone',
      crossOrigin: 'use-credentials',
      icons: [
        {
          src: 'src/pwa-512.png',
          sizes: `512x512`,
          type: `image/png`,
        },
      ],
    },
  },
};

module.exports = config;
