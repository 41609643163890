import * as React from 'react';

const MailchimpLink = ({ children: heading, resource, ...props }) => {
    
    if (resource) {
      return (
        <div id="mc_embed_signup">
            <form action="https://wardell.us9.list-manage.com/subscribe/post?u=8dd4b7cc019c27267e2da3682&amp;id=9597c7879d" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate>
                <div id="mc_embed_signup_scroll">
                <h4 className="form_heading">{heading}</h4>
                <div className="mc-field-group">
                    <label htmlFor="mce-EMAIL">Email Address</label>
                    <input type="email" defautlvalue="" name="EMAIL" className="required email" id="mce-EMAIL" />
                </div>

                <div className="mc-field-group input-group" style={{display: "none"}}>
                <strong>Wardell Resources </strong>
                    <ul>
                        <li><label htmlFor="mce-group[42581]-42581-0"><input type="checkbox" defaultValue="0" name="group[42581][1]" id="mce-group[42581]-42581-0" checked={ resource === "Personal Mission" ? true : false } readOnly />Personal Mission</label></li>
                        <li><label htmlFor="mce-group[42581]-42581-1"><input type="checkbox" defaultValue="2" name="group[42581][2]" id="mce-group[42581]-42581-1" checked={ resource === "Corporate Mission" ? true : false } readOnly />Corporate Mission</label></li>
                        <li><label htmlFor="mce-group[42581]-42581-2"><input type="checkbox" defaultValue="4" name="group[42581][4]" id="mce-group[42581]-42581-2" checked={ resource === "Strategic Objectives" ? true : false } readOnly/>Strategic Objectives</label></li>
                        <li><label htmlFor="mce-group[42581]-42581-3"><input type="checkbox" defaultValue="8" name="group[42581][8]" id="mce-group[42581]-42581-3" checked={ resource === "Key Performance Indicators" ? true : false } readOnly />Key Performance Indicators</label></li>
                        <li><label htmlFor="mce-group[42581]-42581-4"><input type="checkbox" defaultValue="16" name="group[42581][16]" id="mce-group[42581]-42581-4" checked={ resource === "Position Outlines" ? true : false } readOnly />Position Outlines</label></li>
                        <li><label htmlFor="mce-group[42581]-42581-5"><input type="checkbox" defaultValue="32" name="group[42581][32]" id="mce-group[42581]-42581-5" checked={ resource === "Systemization" ? true : false } readOnly />Systemization</label></li>
                        <li><label htmlFor="mce-group[42581]-42581-6"><input type="checkbox" defaultValue="64" name="group[42581][64]" id="mce-group[42581]-42581-6" checked={ resource === "Hiring" ? true : false } readOnly />Hiring</label></li>
                        <li><label htmlFor="mce-group[42581]-42581-7"><input type="checkbox" defaultValue="128" name="group[42581][128]" id="mce-group[42581]-42581-7" checked={ resource === "Employee Evaluation" ? true : false } readOnly />Employee Evaluation</label></li>
                        <li><label htmlFor="mce-group[42581]-42581-8"><input type="checkbox" defaultValue="256" name="group[42581][256]" id="mce-group[42581]-42581-8" checked={ resource === "Performance Review" ? true : false } readOnly />Performance Review</label></li>
                        <li><label htmlFor="mce-group[42581]-42581-9"><input type="checkbox" defaultValue="512" name="group[42581][512]" id="mce-group[42581]-42581-9" checked={ resource === "Target Market Profile" ? true : false } readOnly />Target Market Profile</label></li>
                        <li><label htmlFor="mce-group[42581]-42581-10"><input type="checkbox" defaultValue="1024" name="group[42581][1024]" id="mce-group[42581]-42581-10" checked={ resource === "Positioning Statement" ? true : false } readOnly />Positioning Statement</label></li>
                        <li><label htmlFor="mce-group[42581]-42581-11"><input type="checkbox" defaultValue="2048" name="group[42581][2048]" id="mce-group[42581]-42581-11" checked={ resource === "Budgeting" ? true : false } readOnly />Budgeting</label></li>
                        <li><label htmlFor="mce-group[42581]-42581-12"><input type="checkbox" defaultValue="4096" name="group[42581][4096]" id="mce-group[42581]-42581-12" checked={ resource === "Sales Systems" ? true : false } readOnly />Sales Systems</label></li>
                    </ul>
                </div>
                <div id="mce-responses" className="clear">
                    <div className="response" id="mce-error-response" style={{display: "none"}}></div>
                    <div className="response" id="mce-success-response"style={{display: "none"}}></div>
                </div>
                    <div aria-hidden="true">
                        <input type="text" name="b_8dd4b7cc019c27267e2da3682_9597c7879d" tabIndex="-1" defaultvalue="" style={{position: "absolute", left: "-5000px"}} />
                    </div>
                    <div className="clear">
                        <input type="submit" value={ `Get free ${resource} Resource`} name="subscribe" id="mc-embedded-subscribe" className="button"></input>
                    </div>
                </div>
            </form>
        </div>
      );
    } else {
      return null;
    }
  };

export default MailchimpLink;